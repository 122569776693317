import React from "react"
import FotoKontigo from "@components/pageCasosdeExito/image/imgPhotoEmpresa/kontigo/FotoKontigo.png"
import FotoKontigo2 from "@components/pageCasosdeExito/image/imgPhotoEmpresa/kontigo/FotoKontigo2.png"

//logo de empresas clientes
import Kontigo from "@components/pageCasosdeExito/image/logoscustomer/Kontigo.svg"

//iconos de modulos
import whatsapp from "@components/pageCasosdeExito/image/iconcards/whatsapp.svg"
import inbound from "@components/pageCasosdeExito/image/iconcards/inbound.svg"
import blending from "@components/pageCasosdeExito/image/iconcards/blending.svg"
import chatbot from "@components/pageCasosdeExito/image/iconcards/chatbot.svg"

//iconos metricas
import metrica1 from "@components/pageCasosdeExito/image/imgMetric/kontigo/img1.svg"
import metrica2 from "@components/pageCasosdeExito/image/imgMetric/kontigo/img2.svg"
import metrica3 from "@components/pageCasosdeExito/image/imgMetric/kontigo/img3.svg"

const text1 = (
  <span className="">
    Kontigo, la fintech peruana que brinda su tarjeta electrónica con el
    respaldo de Mastercard, usa Beex para brindar una experiencia ágil y
    excepcional en atención al cliente a sus usuarios.
    <br /> <br />
    Beex ayuda a Kontigo a atender siempre a sus usuarios por sus canales
    preferidos, como WhatsApp, Facebook Messenger o canal de voz. Con la
    implementación de chatbots, Kontigo responde rápidamente a sus usuarios, las
    24 horas del día.
  </span>
)

const text2 = (
  <span>
    Los peruanos tienen en Kontigo la solución financiera digital que buscaban.
    Al contar con su tarjeta electrónica, sus usuarios pueden olvidarse del
    efectivo y recargar su tarjeta cada que quieran, siempre con el respaldo de
    Mastercard.
    <br /> <br />
    Con esto, a través de la aplicación móvil para Android y IOs, sus usuarios
    pueden realizar todo tipo de operaciones desde su celular en cuestión de
    minutos, como pagos de servicios, transferencias, compras y múltiples
    beneficios.
    <br /> <br />
    Analucía Siguas, gerente de Marketing y Producto en GMoney, emisor de la
    tarjeta electrónica Kontigo, ha acompañado en la implementación de las
    soluciones de Beex desde el inicio y nos brinda datos muy interesantes.
  </span>
)

const text3 = (
  <p className="text3-casos" style={{ position: "relative" }}>
    <span style={{ zIndex: "1", position: "sticky" }}>
      {" "}
      Desde que usamos Beex,{" "}
    </span>
    <span
      style={{
        color: "transparent",
        display: "inline",
        left: "0px",
        position: "absolute",
        top: "-2px",
      }}
    >
      Desde que usamos{" "}
      <span
        style={{
          boxShadow: " 0 -14px 0 #f7d731 inset",
        }}
      >
        Beex,
      </span>
    </span>
    tenemos un control más eficiente de las consultas que recibimos en atención
    al cliente, de tal forma que podemos brindar un soporte mucho más rápido y
    eficaz.”
  </p>
)

const text4 = (
  <span>
    La operación de atención al cliente de Kontigo, al tener en una sola
    plataforma todas las consultas de sus diferentes canales, puede atenderlas
    por orden de llegada y prioridad, optimizando sus tiempos de respuesta.
    Además, permite conocer si un mismo cliente se comunica por diversos canales
    para la misma consulta, de tal forma que ratifican sus respuestas.
    <br /> <br />
    En retrospectiva, Analucía cuenta que, antes de Beex, el mantener el
    control de las atenciones era complicado y tedioso, ya que no tenían forma
    de gestionar sus tickets por prioridad, para darles el seguimiento adecuado.
  </span>
)

const text5 = (
  <span>
    “Ahora todo se hace más fácil, rápido y ordenado. Podemos monitorear la
    calidad de la atención que brindan nuestros colaboradores a nuestros
    clientes, permitiéndonos compartir feedback de ser necesario.”
  </span>
)
const text6 = (
  <span>
    El API de WhatsApp Business ha permitido potenciar realmente la comunicación
    de Kontigo con sus usuarios, ya que los casos que llegan a la operación de
    atención al cliente son atendidos en primera instancia por los chatbots
    implementados según rango horario, ya sea que la atención sea diurna o
    nocturna.
    <br /> <br />
    Además, con los visores en tiempo real y módulos de monitoreo de usuarios,
    el departamento de atención al cliente tiene visibilidad real de cómo se
    están gestionando los casos y si los SLAs de la operación son cumplidos.
    <br /> <br />
    También, los números hablan por si solos. Actualmente, Kontigo puede medir
    claramente cuántos tickets de atención están gestionando y en cuánto tiempo.
    También, cuántos de ellos se quedan pendientes de una respuesta final o
    cuántos de ellos fueron cerrados en el primer contacto.
  </span>
)

const text7 = (
  <span>
    “Con Beex, hemos logrado conseguir cerrar hasta en un 98% de las consultas
    en primer contacto. El 2% restante fueron cerrados al día siguiente, dado
    que requerían intervención de otras áreas.”
  </span>
)
const text8 = (
  <p>
    Si bien el canal principal de soporte para los usuarios de Kontigo es el
    digital, principalmente WhatsApp. También cuenta con canal telefónico para
    brindar asistencia a sus usuarios a nivel nacional.
    <br /> <br />
    Kontigo cuenta con un IVR automatizado que deriva los casos a sus áreas
    según la necesidad de los usuarios. Esto, mejora la experiencia del cliente
    que en muchísimos casos prefiere el canal de voz como contacto principal.
    <br /> <br />
    Además, Kontigo ha realizado integraciones que automaticen procesos
    importantes para sus usuarios, como bloquear tarjetas desde canal
    telefónico. Además, se han implementado acciones entre canal de voz y
    WhatsApp, brindando la opción de asistencia por chat si es que el usuario
    tiene mucho tiempo en espera por voz.
  </p>
)

const text9 = (
  <span>
    Sumando a la experiencia de ambos equipos, Beex tiene como premisa
    siempre brindar un acompañamiento total al cliente en todas sus soluciones,
    y Kontigo goza esto a través del mejor SLA brindado por nuestro equipo de Service
    Desk.
    <br /> <br />
    Pero aun mejor, no esperamos que los clientes usen los canales típicos de
    email, sino, también compartimos grupos de WhatsApp y canal de voz para que
    puedan comunicarse siempre que lo necesiten. Esto se ratifica en palabras de
    Analucía:
  </span>
)
const text10 = (
  <span>
    “Con Beex nos sentimos confiados, sumado a las plataformas robustas que
    nos brindan para mejorar siempre nuestra atención al cliente, comparten un
    gran respaldo en soporte ante cualquier inconveniente o requerimiento, de la
    manera más amable y rápida.”
  </span>
)

const text11 = (
  <span>
    Estos son algunos procesos con los que Beex ayuda
    a Kontigo en su operación de atención al cliente:
  </span>
)

const text12 = (
  <p style={{ marginBottom: "70px" }}>
    Beex y Kontigo llevan más de un año de compartir soluciones y
    experiencias que mejoran los procesos de atención al cliente de esta fintech
    Peruana. La sinergia entre los equipos ha sido más que ideal. Esto, gracias
    a que ambas empresas cuentan con una cultura ágil en sus operaciones.
  </p>
)

const data = {
  intro: {
    image: Kontigo,
    link: "https://www.youtube.com/watch?v=Rg5h5lfShlU",
    classlogo: "logo__clientes--kontigo",
    text1: text1,
    text2: text2,
    text3: text3,
    fotoone: FotoKontigo,
    text4: text4,
    text5: text5,
    title6: "WhatsApp como principal canal de contacto para sus usuarios",
    text6: text6,
    text7: text7,
    fototwo: FotoKontigo2,
    title8: "Automatización en canal telefónico y WhatsApp",
    text8: text8,
    title9: "Experiencia en soporte",
    text9: text9,
    title10: "Acompañamiento y respaldo que se siente",
    text10: text10,
    text11: text11,
    text12: text12,

    metric1: metrica1,
    metric2: metrica2,
    metric3: metrica3,
  },

  cards: [
    {
      title: "WhatsApp",
      img: whatsapp,
      info:
        "Gestión conversacional de atención al cliente vía WhatsApp Business API, permitiendo que sus clientes conversen con Kontigo mediante la App más usada a nivel mundial. Contando con enrutamiento inteligente, asignación de casos y más.",
    },
    {
      title: "Chatbot",
      img: chatbot,
      info:
        "Implementación de chatbots por los canales de más interacción con sus clientes, como WhatsApp y Facebook Messenger. Diseño y creación por rangos horarios para compartir respuestas eficaces y rápidas, con disponibilidad 24x7, los 365 días del año.",
    },
    {
      title: "Telefonía Inbound",
      img: inbound,
      info:
        "Enrutamos las llamadas entrantes en la central telefónica de Kontigo, ante las consultas o dudas de sus usuarios, respetando sus tiempos de atención, acorde a sus niveles de servicio.",
    },
    {
      title: "Blending",
      img: blending,
      info:
        "Sus colaboradores recepcionan, pero también realizan llamadas salientes ante cualquier necesidad. Reduciendo los tiempos muertos de su operación y optimizando su gestión diaria con IVRs.",
    },
  ],
}

export default data
